import {
  Box,
  Tooltip,
  Badge,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Logo.css';
import { useState } from 'react';

const LogoWrapperLink = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 100px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Logo({ redirect, provider }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const getProviderLogo = (provider) => {
    switch (provider) {
      case 1:
        return '/static/images/unidad-coronaria-logo.png';
      case 2:
        return '/static/images/emeca-salud-logo.png';
      case 3:
        return '/static/images/ayuda-medica-logo.png';
      case 4:
        return '/static/images/sume-medica-logo.png';
      case 5:
        return '/static/images/ayuda-medica-logo.png';
      case 'gam':
        return 'https://www.grupoayudamedica.com.ar/wp-content/uploads/2022/11/cropped-Logo-GAM-1-1.png';
      default:
        return '/static/images/dec-logo.png';
    }
  };


  const getWidhtProvider = (provider) => {
    switch (provider) {
      case 'gam':
        return '130px';
      default:
        return '350px';
    }
  };

  return (
    <Box>
      {redirect ? (
        <LogoWrapperLink to="/">
          <img
            style={{ width: 'auto' }}
            src={getProviderLogo(provider)}
            className="icon"
            alt="Logo"
          />
        </LogoWrapperLink>
      ) : (
        <Box display="flex" justifyContent="center">
          <img
            style={{ width: getWidhtProvider(provider) }}
            src={getProviderLogo(provider)}
            className="icon"
            alt="Logo"
          />
        </Box>
      )}
    </Box>
  );
}

export default Logo;
