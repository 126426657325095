import { configureStore } from '@reduxjs/toolkit';
import empresasReducer from './empresaSlice';
import errorReducer from './errorSlice';

const AgmStore = configureStore({
  reducer: {
    empresas: empresasReducer,
    error: errorReducer
  }
});

export default AgmStore;
