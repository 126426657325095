import axios from 'axios';

const axiosAffiliate = axios.create({
  baseURL: process.env.REACT_APP_URL_DEC_API
});

const axiosAdmin = axios.create({
  baseURL: process.env.REACT_APP_URL_DEC_API_ADMIN
});

axiosAffiliate.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

axiosAdmin.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

export {axiosAffiliate, axiosAdmin};
export default axiosAffiliate;
