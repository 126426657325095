import {
  Box,
  Card,
  Typography,
  Container,
  styled,
  Divider,
  Button,
  CircularProgress
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useState } from 'react';
import AuthContext from 'src/contexts/JWTAuthContext';
import Logo from 'src/components/LogoSign';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import QRCode from 'react-qr-code';
import Barcode from 'react-barcode';
import { usePDF } from 'react-to-pdf';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
  `
);

function Prescription() {
  const { method } = useContext(AuthContext);
  const { id: idVideocall } = useParams();
  const [prescriptionData, setPrescriptionData] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [back, setBack] = useState('#FFFFFF');
  const [fore, setFore] = useState('#000000');
  const [size, setSize] = useState(150);
  const [signatureURL, setSignatureURL] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!prescriptionData) {
      getPrescription();
    }
  }, [prescriptionData]);

  const getPrescription = async () => {
    try {
      const url = `https://paciente.doctorencasa.com.ar:5001/prescription/${idVideocall}`;
      const prescription = await axios.get(url);
      if (!prescription.data.data) {
        console.log('no data');
        return navigate('/404');
      }
      setPrescriptionData(prescription.data.data);
      setIsFetchingData(false);
    } catch (err) {
      console.log('error', err);
      return navigate('/404');
    }
  };

  const formattDate = (rawDate) => {
    const date = new Date(rawDate);
    let dia: any = date.getUTCDate();
    let mes: any = date.getUTCMonth() + 1;
    const año = date.getUTCFullYear();
    dia = dia < 10 ? '0' + dia : dia;
    mes = mes < 10 ? '0' + mes : mes;
    return dia + '/' + mes + '/' + año;
  };

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const prescriptionsPerPage = 1;

  const totalPages = Math.ceil(
    prescriptionData?.prescriptions.length / prescriptionsPerPage
  );

  const currentPrescription = prescriptionData?.prescriptions[currentPageIndex];

  const goToNextPage = () => {
    setCurrentPageIndex((prevIndex) =>
      prevIndex < totalPages - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const goToPreviousPage = () => {
    setCurrentPageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const { toPDF, targetRef } = usePDF({
    filename: `receta-difital-${currentPrescription?.id}.pdf`
  });

  useEffect(() => {
    setIsFetchingData(true);
    if (currentPrescription?.videocall?.doctor?.bucket_signature_url) {
      fetch(currentPrescription?.videocall?.doctor?.bucket_signature_url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept'
        }
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          const localSignatureUrl = URL.createObjectURL(blob);
          setSignatureURL(localSignatureUrl);
          setIsFetchingData(false);
        })
        .catch((error) => {
          console.error(
            'There has been a problem with your fetch operation:',
            error
          );
          setIsFetchingData(false);
        });
    } else {
      setIsFetchingData(false);
    }
  }, [currentPrescription?.videocall?.doctor?.bucket_signature_url]);

  return (
    <>
      <Helmet>
        <title>Receta Médica</title>
      </Helmet>
      <MainContent>
        {isFetchingData ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '8px'
              }}
            >
              <Button
                color="primary"
                style={{ width: '220px', marginRight: '20px' }}
                size="large"
                variant="contained"
                onClick={() => toPDF()}
              >
                Descargar Receta
              </Button>
            </Box>
            {currentPrescription && (
              <>
                <TopWrapper>
                  <Container maxWidth="sm" ref={targetRef}>
                    <Card
                      sx={{
                        mt: 3,
                        px: 4,
                        pt: 3,
                        pb: 3
                      }}
                    >
                      <Box>
                        <Logo
                          redirect={false}
                          provider={
                            currentPrescription?.videocall?.affiliate
                              ?.provider_id
                          }
                        />
                      </Box>
                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{ marginX: '8px', marginY: '24px' }}
                      />
                      <Box
                        display={{ xs: 'block', md: 'flex' }}
                        flexDirection="column"
                        gap={0.5}
                        justifyContent="flex-start"
                        alignItems={{ xs: 'center', md: 'flex-start' }}
                        marginY="8px"
                      >
                        <Typography>
                          {' '}
                          Fecha de Receta:{' '}
                          {formattDate(currentPrescription?.date)}
                        </Typography>
                        <Typography>
                          {' '}
                          Nombre y Apellido: {currentPrescription?.patientname}
                        </Typography>
                        <Typography>
                          {' '}
                          Cobertura Médica:{' '}
                          {currentPrescription?.medicalcoverage}
                        </Typography>
                        <Typography>
                          {' '}
                          Nro de Afiliado: {currentPrescription?.memberid}
                        </Typography>
                        <Typography>
                          {' '}
                          Plan: {currentPrescription?.planname}
                        </Typography>
                        <Typography>
                          {' '}
                          DNI: {currentPrescription?.documentnumber}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          marginY: '24px'
                        }}
                      >
                        <Typography variant="h4"> Rp </Typography>
                        <Box
                          sx={{
                            marginX: '24px',
                            marginY: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px'
                          }}
                        >
                          {currentPrescription?.prescriptionItems?.map(
                            ({ drug, quantity }) => (
                              <>
                                <Typography fontWeight={'bold'}>
                                  {' '}
                                  {drug}
                                </Typography>
                                <Typography
                                  sx={{ marginX: '16px', marginY: '-6px' }}
                                >
                                  {' '}
                                  Cantidad: {quantity}
                                </Typography>
                              </>
                            )
                          )}
                        </Box>
                      </Box>
                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{ marginX: '8px', marginY: '24px' }}
                      />
                      {!signatureURL ? (
                        <Typography component="span" variant="h4">
                          {'Firma digitalmente Dr. ' +
                            currentPrescription?.videocall?.doctor?.firstname +
                            ' ' +
                            currentPrescription?.videocall?.doctor?.lastname}
                        </Typography>
                      ) : (
                        <>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div>
                              <Typography
                                component="span"
                                variant="h4"
                                style={{ marginRight: '10px' }}
                              >
                                {'Firma digitalmente Dr. ' +
                                  currentPrescription?.videocall?.doctor
                                    ?.firstname +
                                  ' ' +
                                  currentPrescription?.videocall?.doctor
                                    ?.lastname}
                              </Typography>
                              <br />
                              <Typography component="span" variant="h4">
                                Matrícula{' '}
                                {
                                  currentPrescription?.videocall?.doctor
                                    ?.nationalenrollment
                                }{' '}
                              </Typography>
                            </div>
                            <img
                              src={signatureURL}
                              style={{ width: '150px', height: 'auto' }}
                              alt="Firma digital del doctor"
                            />
                          </div>
                        </>
                      )}
                      <br />

                      <Box
                        display={{ xs: 'block', md: 'flex' }}
                        justifyContent={{
                          xs: 'flex-start',
                          md: 'space-between'
                        }}
                        alignItems="center"
                        marginY="8px"
                        gap={2}
                      >
                        <Barcode
                          value={currentPrescription.id}
                          height={30}
                        ></Barcode>
                        <QRCode
                          value={`https://paciente.doctorencasa.com.ar/receta-digital/${currentPrescription.id}/farmacia`}
                          bgColor={back}
                          fgColor={fore}
                          size={size}
                        />
                      </Box>
                    </Card>
                  </Container>
                </TopWrapper>
              </>
            )}
            {currentPrescription && (
              <Box
                display="flex"
                justifyContent="center"
                gap={1}
                marginBottom={4}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={2}
                >
                  <Button
                    variant="contained"
                    onClick={goToPreviousPage}
                    disabled={currentPageIndex === 0}
                  >
                    Anterior
                  </Button>
                  <span>
                    Página {currentPageIndex + 1} de {totalPages}
                  </span>
                  <Button
                    variant="contained"
                    onClick={goToNextPage}
                    disabled={currentPageIndex === totalPages - 1}
                  >
                    Siguiente
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </MainContent>
    </>
  );
}

export default Prescription;
