import * as Yup from 'yup';
import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from 'src/contexts/JWTAuthContext';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress
} from '@mui/material';

const LoginJWT: FC = () => {
  const { login } = useContext(AuthContext);
  const [error, setError] = useState<string>(null);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('El email ingresado es invalido')
          .max(255)
          .required('El email es obligatorio'),
        password: Yup.string().max(255).required('La contraseña es obligatoria')
      })}
      onSubmit={async (values): Promise<void> => {
        try {
          await login(values.email, values.password);
        } catch (err) {
          console.error('Catch', err);
          console.log(err);
          if (err.message == 'Unauthorized') {
            setError('Usuario o contraseña incorrecto');
          } else {
            setError(err.message);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.email && errors.email}
            label={'Email'}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password}
            label={'Contraseña'}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />

          <Box
            alignItems="center"
            display={{ xs: 'block', md: 'flex' }}
            marginY="8px"
          >
            <Link component={RouterLink} to="/account/recover-password">
              <b>Olvido su contraseña?</b>
            </Link>
          </Box>

          {error && (
            <Box
              alignItems="center"
              display={{ xs: 'block', md: 'flex' }}
              marginY="8px"
            >
              <b style={{ color: 'red', textDecoration: 'underline' }}>
                {error}
              </b>
            </Box>
          )}

          <Button
            style={{ marginTop: '8px' }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            Ingresar
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
