import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorState {
  errorActual: String;
}

const initialState: ErrorState = {
  errorActual: null
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    updateErrorActual: (state, action) => {
      state.errorActual = action.payload;
    }
  }
});

export const { updateErrorActual } = errorSlice.actions;

export default errorSlice.reducer;
