import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';
import Authenticated from '../components/Authenticated';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const LoginBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Basic'))
);

const RecoverPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);

const DeleteAccount = Loader(
  lazy(() => import('src/content/pages/Auth/DeleteAccount'))
);

const accountRoutes = [
  {
    path: 'login',
    element: (
      <Guest>
        <LoginBasic />
      </Guest>
    )
  },
  {
    path: 'recover-password',
    element: <RecoverPassword />
  },
  {
    path: 'delete-account',
    element: (
      <Authenticated>
        <DeleteAccount />
      </Authenticated>
    )
  }
];

export default accountRoutes;
