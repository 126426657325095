import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';

import dashboardsRoutes from './dashboards';
import blocksRoutes from './blocks';
import accountRoutes from './account';
import DeleteAccount from '../content/pages/Auth/DeleteAccount';
import Prescription from '../content/pages/Prescription';
import PharmacyPrescriptionForm from '../content/pages/Prescription/PharmacyPrescriptionForm';
import MedicalReceipt from '../content/pages/MedicalRecipt';
// import MedicalReceipt from '../content/pages/MedicalRecipt';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const router: RouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '/',
    element: (
      <Authenticated>
        <DeleteAccount />
      </Authenticated>
    )
    // {
    //   path: '/',
    //   element: (
    //     <Authenticated>
    //       <ExtendedSidebarLayout />
    //     </Authenticated>
    //   ),
    // children: [
    //   {
    //     path: '',
    //     element: <Navigate to="dashboards" replace />
    //   },
    //   {
    //     path: 'dashboards',
    //     children: dashboardsRoutes
    //   },
    //   {
    //     path: 'blocks',
    //     children: blocksRoutes
    //   }
    // ]
  },
  {
    path: '/receta-digital/:id',
    element: <Prescription />
  },
  {
    path: '/receta-digital/:id/farmacia',
    element: <PharmacyPrescriptionForm />
  },
  {
    path: '/comprobante-consulta/:id',
    element: <MedicalReceipt />
  },
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: 'status',
    children: [
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      }
    ]
  }
];

export default router;
