import axios from 'axios';
import { object } from 'prop-types';

const requestSinJwt = async (
  endpoint: String,
  data: Object,
  method: String
): Promise<any> => {
  if (method === 'GET') {
    const response = await axios
      .get(
        data
          ? process.env.REACT_APP_URL_BACKEND + endpoint + '/' + data
          : process.env.REACT_APP_URL_BACKEND + endpoint
      )
      .then((response) => {
        return response.data;
      });
  } else if (method === 'POST') {

    const response = await axios.post(
      process.env.REACT_APP_URL_BACKEND + endpoint,
      {
        headers: {
          'Content-type': 'application/json'
        },
        data: data
      }
    );
  } else if (method === 'PUT') {
    const response = await axios.put(
      process.env.REACT_APP_URL_BACKEND + endpoint,
      {
        headers: {
          'Content-type': 'application/json'
        },
        data: data
      }
    );
  }
};
const requestId = async (
  endpoint: String,
  data: Object,
  method: String,
): Promise<any> => {
  const token = localStorage.getItem('accessToken') || '';
  if (method === 'GET') {
    const response = await axios.get(
      process.env.REACT_APP_URL_BACKEND + endpoint,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-token': token
        },
        params: data ? data : null
      }
    );
    return response.data;
  } 
};

const requestConJwt = async (
  endpoint: String,
  data: Object,
  method: String
): Promise<any> => {
  const token = localStorage.getItem('accessToken') || '';
  if (method === 'GET') {
    const response = await axios.get(
      process.env.REACT_APP_URL_BACKEND + endpoint,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-token': token
        },
        params: data ? data : null
      }
    );
    return response.data;
  } else if (method === 'POST') {
    const response = await axios.post(
      process.env.REACT_APP_URL_BACKEND + endpoint,
      {
        headers: {
          'Content-type': 'application/json',
          'x-token': token
        },
        data: data
      }
    );
    return response.data;
  } else if (method === 'PUT') {
    const response = await axios.put(
      process.env.REACT_APP_URL_BACKEND + endpoint,
      {
        headers: {
          'Content-type': 'application/json'
        },
        data: data
      }
    );
    return response.data;
  }
};

const checkToken = async () => {
  const accessToken = window.localStorage.getItem('accessToken');
  const response = await axios
    .get(process.env.REACT_APP_URL_BACKEND + '/auth/checkTwj', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-token': accessToken
      }
    })
    .then(function (response) {
      return response.data;
    });
};

export { requestSinJwt, requestConJwt, checkToken, requestId};
