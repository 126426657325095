import {
  Box,
  Card,
  Typography,
  Container,
  styled,
  Divider,
  Button,
  CircularProgress
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useState } from 'react';
import AuthContext from 'src/contexts/JWTAuthContext';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { usePDF } from 'react-to-pdf';
import Logo from '../../../components/LogoSign';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
  `
);

function MedicalReceipt() {
  const { method } = useContext(AuthContext);
  const { id: idVideoCall } = useParams();
  const [medicalReciptData, setmedicalReciptData] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [formattedDate, setFormattedDate] = useState('');
  const [signatureURL, setSignatureURL] = useState(null);

  const navigate = useNavigate();

  const { toPDF, targetRef } = usePDF({
    filename: `comprobante-atencion-${idVideoCall}.pdf`
  });

  useEffect(() => {
    if (!medicalReciptData) {
      getReceipt();
    }
  }, [medicalReciptData]);

  useEffect(() => {
    setIsFetchingData(true);
    if (medicalReciptData?.videocall?.doctor?.bucket_signature_url) {
      fetch(medicalReciptData?.videocall?.doctor?.bucket_signature_url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept'
        }
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          const localSignatureUrl = URL.createObjectURL(blob);
          setSignatureURL(localSignatureUrl);
          setIsFetchingData(false);
        })
        .catch((error) => {
          console.error(
            'There has been a problem with your fetch operation:',
            error
          );
          setIsFetchingData(false);
        });
    } else {
      setIsFetchingData(false);
    }
  }, [medicalReciptData?.videocall?.doctor?.bucket_signature_url]);

  const getReceipt = async () => {
    try {
      const url = `https://paciente.doctorencasa.com.ar:5001/receipt/${idVideoCall}`;
      const receipt = await axios.get(url);
      if (!receipt.data.data) {
        console.log('no data');
        return navigate('/404');
      }
      setmedicalReciptData(receipt.data.data);
      setIsFetchingData(false);
      const date = new Date(receipt.data.data?.videocall.startdate);
      let dia: any = date.getUTCDate();
      let mes: any = date.getUTCMonth() + 1;
      const año = date.getUTCFullYear();
      dia = dia < 10 ? '0' + dia : dia;
      mes = mes < 10 ? '0' + mes : mes;
      setFormattedDate(dia + '/' + mes + '/' + año);
    } catch (err) {
      console.log('err', err);
      return navigate('/404');
    }
  };
  const medicalReasons = medicalReciptData?.medicalRecordReason
    ?.map((reason) => reason?.name)
    .join(', ');

  return (
    <>
      <Helmet>
        <title>Constancia de consulta médica</title>
      </Helmet>
      <MainContent>
        {isFetchingData ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '8px'
              }}
            >
              <Button
                color="primary"
                style={{ width: '220px', marginRight: '20px' }}
                size="large"
                variant="contained"
                onClick={() => toPDF()}
              >
                Descargar comprobante
              </Button>
              {medicalReciptData?.videocall?.prescription && (
                <Link to={`/receta-digital/${idVideoCall}`}>
                  <Button
                    color="primary"
                    style={{ width: '220px' }}
                    size="large"
                    variant="contained"
                  >
                    Ver receta digital
                  </Button>
                </Link>
              )}
            </Box>
            <TopWrapper>
              <Container maxWidth="md" ref={targetRef}>
                <Card
                  sx={{
                    mt: 3,
                    px: 4,
                    pt: 3,
                    pb: 3
                  }}
                >
                  <Logo
                    redirect={false}
                    provider={
                      medicalReciptData?.videocall?.affiliate?.provider_id
                    }
                  />
                  <Divider
                    orientation="horizontal"
                    flexItem
                    sx={{ marginX: '8px', marginY: '24px' }}
                  />
                  <Box
                    display={{ xs: 'block', md: 'flex' }}
                    flexDirection="column"
                    gap={1.5}
                    justifyContent="flex-start"
                    alignItems={{ xs: 'center', md: 'flex-start' }}
                    marginY="8px"
                  >
                    <h3> Constancia de consulta y asesoramiento médico:</h3>
                    <Typography variant="h5">
                      {' '}
                      Ref: {medicalReciptData?.patient}
                    </Typography>
                    <Typography variant="h5">
                      {' '}
                      De mi mayor Consideración,
                    </Typography>
                    <Typography variant="h5">
                      Por medio de la presente, dejo constancia que el día{' '}
                      {formattedDate}, bajo el número de Prestación{' '}
                      {medicalReciptData?.videocall_id}, se asistió al paciente
                      de la referencia.
                    </Typography>
                    <Typography variant="h5">
                      Tal como consta en nuestros registros del día
                      anteriormente mencionado el motivo de llamado fue:{' '}
                      {medicalReasons}. {medicalReciptData?.recommendation}
                    </Typography>
                    <Typography variant="h5">
                      Sin más, saludo a Ud. atentamente, quedando a su entera
                      disposición para las ampliaciones que considere
                      conveniente.
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {!signatureURL ? (
                      <>
                        <Typography component="span" variant="h5">
                          {'Firma digitalmente Dr. ' +
                            medicalReciptData?.videocall?.doctor?.firstname +
                            ' ' +
                            medicalReciptData?.videocall?.doctor?.lastname}
                        </Typography>
                        <Typography component="span" variant="h5">
                          Matrícula{' '}
                          {
                            medicalReciptData?.videocall?.doctor
                              ?.nationalenrollment
                          }
                        </Typography>
                      </>
                    ) : (
                      <>
                        <img
                          src={signatureURL}
                          style={{
                            width: '150px',
                            height: 'auto',
                            marginRight: '10px'
                          }}
                          alt="Firma digital del doctor"
                        />
                        <Typography component="span" variant="h5">
                          {'Firma digitalmente Dr. ' +
                            medicalReciptData?.videocall?.doctor?.firstname +
                            ' ' +
                            medicalReciptData?.videocall?.doctor?.lastname}
                        </Typography>
                        <Typography component="span" variant="h5">
                          Matrícula{' '}
                          {
                            medicalReciptData?.videocall?.doctor
                              ?.nationalenrollment
                          }
                        </Typography>
                      </>
                    )}
                  </Box>
                  <br />
                </Card>
              </Container>
            </TopWrapper>
          </>
        )}
      </MainContent>
    </>
  );
}

export default MedicalReceipt;
