import * as Yup from 'yup';
import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from 'src/contexts/JWTAuthContext';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress
} from '@mui/material';

const DeleteAccountForm: FC = () => {
  const { disableUser, logout, user } = useContext(AuthContext);
  const [error, setError] = useState<string>(null);

  return (
    <Formik
      initialValues={{}}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values): Promise<void> => {
        try {
          await disableUser(Number(user.id));
        } catch (err) {
          console.error('Catch', err);
          setError(err.msg);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <Box padding={2}>
          <form noValidate onSubmit={handleSubmit}>
            <Typography fontSize={16} component="h3" align="center">
              {' '}
              ¡Hola, {user.username}!
            </Typography>
            <Box marginY="16px" justifyContent={'center'}>
              <Typography variant="h3" component="h3" align="center">
                {' '}
                ¿Estás seguro de borrar tu cuenta?
              </Typography>
            </Box>
            <Typography align="center" fontWeight={600} color={'error'}>
              {' '}
              Ten en cuenta que todos los datos de tu cuenta se borrarán
            </Typography>
            <Box
              justifyContent={'space-between'}
              display={'flex'}
              marginY="40px"
              gap={4}
            >
              <Button
                style={{ marginTop: '8px' }}
                color="primary"
                type="submit"
                size="large"
                variant="contained"
                onClick={() => logout()}
                fullWidth
              >
                Cancelar
              </Button>
              <Button
                style={{ marginTop: '8px' }}
                color="error"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                fullWidth
                disabled={isSubmitting}
                type="submit"
                size="large"
                variant="contained"
              >
                Borrar
              </Button>
            </Box>

            {error && (
              <Box
                alignItems="center"
                display={{ xs: 'block', md: 'flex' }}
                marginY="8px"
              >
                <b style={{ color: 'red', textDecoration: 'underline' }}>
                  {error}
                </b>
              </Box>
            )}
          </form>
        </Box>
      )}
    </Formik>
  );
};

export default DeleteAccountForm;
