import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Empresa } from 'src/models/empresa';

interface EmpresasState {
  empresasActuales: Empresa[];
}

const initialState: EmpresasState = {
  empresasActuales: []
};

export const empresasSlice = createSlice({
  name: 'empresas',
  initialState,
  reducers: {
    updateEmpresasActuales: (state, action) => {
      state.empresasActuales = action.payload;
    }
  }
});

export const { updateEmpresasActuales } = empresasSlice.actions;

export default empresasSlice.reducer;
