import * as Yup from 'yup';
import {
  Box,
  Card,
  Container,
  styled,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  Dialog,
  Alert,
  Collapse,
  Avatar,
  Slide,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { Helmet } from 'react-helmet-async';
import {
  useContext,
  useState,
  Ref,
  ReactElement,
  forwardRef,
  useEffect
} from 'react';
import AuthContext from 'src/contexts/JWTAuthContext';
import Logo from 'src/components/LogoSign';
import { useParams, useNavigate } from 'react-router-dom';
import { TransitionProps } from '@mui/material/transitions';
import { Formik } from 'formik';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';
import axios from 'axios';
import { set } from 'nprogress';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
  `
);

const TopWrapper = styled(Box)(
  () => `
    display: flex;
    width: 100%;
    flex: 1;
    padding: 20px;
    `
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      top: -${theme.spacing(1)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const AvatarFail = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.main};
      color: ${theme.palette.error.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.error};
      top: -${theme.spacing(1)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

function PharmacyPrescriptionForm() {
  const { method } = useContext(AuthContext);
  const { id: idPrescription } = useParams();
  const [error, setError] = useState<string>(null);
  const [prescriptionData, setPrescriptionData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [prescriptionStatus, setPrescriptionStatus] = useState('pendiente');
  const [usedPrescriptionData, setUsedPrescriptionData] = useState({
    status: '',
    address: ''
  });

  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (!prescriptionData) {
      getPrescription();
    }
  }, [prescriptionData]);

  const getPrescription = async () => {
    try{
      const url = `https://paciente.doctorencasa.com.ar:5001/prescription/pharmacy/${idPrescription}`;
      const prescription = await axios.get(url);
      if (!prescription.data.data) {
        console.log('no data');
        return navigate("/404");
      }
      setPrescriptionData(prescription.data.data);
      if (prescription?.data?.data?.prescriptionStatus) {
        const prescriptionStatus = prescription.data.data.prescriptionStatus
        setUsedPrescriptionData({status: prescriptionStatus?.status, address: prescriptionStatus?.pharmacy_address})
        setPrescriptionStatus(prescription.data.data.prescriptionStatus?.status);
      }
      setIsFetchingData(false);
    } catch(err){
      console.log('err', err)
      return navigate("/404");
    }

  };

  const usePrescription = async ({ address, status }) => {
    const statusMap = {
      Bloqueado: 'bloqueado',
      Entregado: 'entregado'
    };
    const url = `https://paciente.doctorencasa.com.ar:5001/prescription/${idPrescription}`;
    const response = await axios.post(url, {
      status: statusMap[status],
      pharmacy_address: address
    });
    if (!response.data.success) {
      setError('No se pudo actualizar la receta, intente más tarde');
    }
    setUsedPrescriptionData({ status: statusMap[status], address });
    setPrescriptionStatus(statusMap[status]);
    return response;
  };

  return (
    <>
      <Helmet>
        <title>Pharmacy Prescription Form</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm" sx={{ gap: '10px' }}>
            <Card
              sx={{
                mt: 3,
                px: 4,
                pt: 3,
                pb: 3
              }}
            >
              {prescriptionStatus === 'pendiente' ? (
                <>
                  <Logo
                    redirect={false}
                    provider={
                      prescriptionData?.videocall?.affiliate?.provider_id
                    }
                  />
                  <Box sx={{ display: 'column', marginY: '10px' }}>
                    <Typography
                      variant="h2"
                      sx={{
                        mb: 1
                      }}
                    >
                      {'Utilizar Receta Digital'}
                    </Typography>
                    <Typography
                      variant="h4"
                      color="text.secondary"
                      fontWeight="normal"
                      sx={{
                        mb: 3
                      }}
                    >
                      {
                        'Introduzca la dirección de la farmacia y el estado para consumir la receta'
                      }
                    </Typography>
                  </Box>
                  <Formik
                    initialValues={{
                      address: '',
                      status: 'entregado',
                      submit: null
                    }}
                    validationSchema={Yup.object().shape({
                      address: Yup.string()
                        .max(255)
                        .required('La dirección es obligatoria'),
                      status: Yup.string()
                        .oneOf(['Entregado', 'Bloqueado'])
                        .required('El estado de la receta es obligatorio')
                    })}
                    onSubmit={async (values): Promise<void> => {
                      try {
                        await usePrescription({
                          address: values.address,
                          status: values.status
                        });
                      } catch (err) {
                        console.error('Catch', err);
                        console.log(err);
                        if (err.message == 'Unauthorized') {
                          setError('No se pudo actualizar la receta');
                        } else {
                          setError(err.message);
                        }
                      }
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                      values
                    }): JSX.Element => (
                      <form noValidate onSubmit={handleSubmit}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px'
                          }}
                        >
                          <InputLabel>{'Dirección de la farmacia'}</InputLabel>
                          <PlacesAutocomplete
                            value={values.address}
                            onChange={(address) =>
                              setFieldValue('address', address)
                            }
                            onSelect={(address) =>
                              setFieldValue('address', address)
                            }
                            searchOptions={{
                              types: ['address'],
                              componentRestrictions: { country: 'ar' },
                              region: 'AR-B'
                            }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading
                            }) => (
                              <div>
                                <TextField
                                  {...getInputProps({
                                    variant: 'outlined',
                                    InputLabelProps: { shrink: false },
                                    fullWidth: true
                                  })}
                                />
                                {loading && <CircularProgress />}
                                {!loading && suggestions.length > 0 && (
                                  <Paper>
                                    {suggestions.map((suggestion) => (
                                      <MenuItem
                                        {...getSuggestionItemProps(suggestion, {
                                          className: 'suggestion-item',
                                          onClick: () =>
                                            setFieldValue(
                                              'address',
                                              suggestion.description
                                            )
                                        })}
                                        key={suggestion.placeId}
                                        onClick={() =>
                                          setFieldValue(
                                            'address',
                                            suggestion.description
                                          )
                                        }
                                      >
                                        {suggestion.description}
                                      </MenuItem>
                                    ))}
                                  </Paper>
                                )}
                              </div>
                            )}
                          </PlacesAutocomplete>

                          <InputLabel>{'Estado de la receta'}</InputLabel>
                          <Select
                            value={values.status || 'Entregado'}
                            name="status"
                            onChange={handleChange}
                            label={'Estado de la receta'}
                            fullWidth
                          >
                            {['Entregado', 'Bloqueado'].map((statusOption) => (
                              <MenuItem
                                key={statusOption}
                                value={statusOption}
                                selected
                              >
                                {statusOption}
                              </MenuItem>
                            ))}
                          </Select>

                          {error && (
                            <Box
                              alignItems="center"
                              display={{ xs: 'block', md: 'flex' }}
                              marginY="8px"
                            >
                              <b
                                style={{
                                  color: 'red',
                                  textDecoration: 'underline'
                                }}
                              >
                                {error}
                              </b>
                            </Box>
                          )}

                          <Button
                            style={{ marginTop: '8px' }}
                            color="primary"
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size="1rem" />
                              ) : null
                            }
                            fullWidth
                            size="large"
                            variant="contained"
                            disabled={isSubmitting}
                            type="submit"
                          >
                            Enviar
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </>
              ) : (
                <Box>
                  {prescriptionStatus === 'entregado' ? (
                    <AvatarSuccess>
                      <CheckTwoToneIcon />
                    </AvatarSuccess>
                  ) : (
                    <AvatarFail>
                      <CloseTwoToneIcon />
                    </AvatarFail>
                  )}

                  <Typography
                    align="center"
                    sx={{
                      py: 4,
                      px: 10
                    }}
                    variant="h3"
                  >
                    Esta receta digital se encuentra en estado{' '}
                    {prescriptionStatus}
                  </Typography>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{
                      mb: 3
                    }}
                  >
                    Esta receta fue actualizada a {usedPrescriptionData.status}{' '}
                    en {usedPrescriptionData?.address}
                  </Typography>
                </Box>
              )}
            </Card>
          </Container>
        </TopWrapper>

        <DialogWrapper
          open={openDialog}
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDialog}
        >
          <Box
            sx={{
              px: 4,
              pb: 4,
              pt: 10
            }}
          >
            <AvatarSuccess>
              <CheckTwoToneIcon />
            </AvatarSuccess>

            <Typography
              align="center"
              sx={{
                py: 4,
                px: 10
              }}
              variant="h3"
            >
              {'La receta se actualizó con éxito'}
            </Typography>

            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={handleCloseDialog}
            >
              {'Volver'}
            </Button>
          </Box>
        </DialogWrapper>
      </MainContent>
    </>
  );
}

export default PharmacyPrescriptionForm;
