import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { StylesProvider } from '@mui/styles';

export const ThemeContext = React.createContext(
  (themenombre: string): void => {}
);

const ThemeProviderWrapper = (props) => {
  const curThemenombre = localStorage.getItem('appTheme') || 'PureLightTheme';
  const [themenombre, _setThemenombre] = useState(curThemenombre);
  const theme = themeCreator(themenombre);
  const setThemenombre = (themenombre: string): void => {
    localStorage.setItem('appTheme', themenombre);
    _setThemenombre(themenombre);
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemenombre}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
